import React, { useState, useEffect, useRef } from "react";
import { Map, Source, Layer, MapRef, Popup, Marker } from "react-map-gl";
import { wildfireStyle, heatwaveStyle, waterStressStyle, cycloneStyle, floodStyle, slrStyle } from "../Layout/LayerStyle";

const AdminPolygon = (props) => {
  const {
    hazardScoreMetadata, 
    selectedHazard,
    selectedTemperatureScenario,
    yearRange,
    adminPolygonData,
    source_id
   } = props;

  const [adminPolygonStyle, setAdminPolygonStyle] = useState();

  // Set active map data based on selected hazard and temperature scenario
  useEffect(() => {
    if (selectedHazard) {
      let scenarioLabel =
        selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
      let selectedHazardPropName = selectedHazard === 'SLR' ? 'sea_level_rise' : selectedHazard;
      const hazardLabel = `${selectedHazardPropName}_${scenarioLabel}_${yearRange}_summary_score`;
      const min = hazardScoreMetadata[scenarioLabel][selectedHazardPropName][yearRange].min;
      const max = hazardScoreMetadata[scenarioLabel][selectedHazardPropName][yearRange].max;

      let newPolygonStyle = {};
      if (selectedHazard === "wildfire") {
        newPolygonStyle = JSON.parse(JSON.stringify(wildfireStyle));
      } else if (selectedHazard === "heatwave") {
        newPolygonStyle = JSON.parse(JSON.stringify(heatwaveStyle));
      } else if (selectedHazard === "water_stress") {
        newPolygonStyle = JSON.parse(JSON.stringify(waterStressStyle));
      } else if (selectedHazard === "cyclone") {
        newPolygonStyle = JSON.parse(JSON.stringify(cycloneStyle));
      } else if (selectedHazard === "flood") {
        newPolygonStyle = JSON.parse(JSON.stringify(floodStyle));
      } else if (selectedHazard === "SLR") {
        newPolygonStyle = JSON.parse(JSON.stringify(slrStyle));
      }
      newPolygonStyle.id = `${source_id}-layer`;
      newPolygonStyle.paint["fill-color"][2][1] = hazardLabel;
      // newPolygonStyle.paint["fill-color"][3] = min;     
      newPolygonStyle.paint["fill-color"][7] = max;
      setAdminPolygonStyle(newPolygonStyle);
    } else {
      setAdminPolygonStyle(null);
    }
  }, [selectedHazard, selectedTemperatureScenario, yearRange]);

  return (
    <Source id={source_id} type="geojson" data={adminPolygonData}>
      <Layer {...adminPolygonStyle} />
    </Source>
  );
};

export default AdminPolygon;
