import React, {useState, useContext, useEffect} from 'react';
import "./ExposureHistogram.css";
import { Bar } from "react-chartjs-2";
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config'

const ExposureHistogram = (props) => {
    const { 
        selectedTemperatureScenario,
        selectedAssetProperties,
        selectedPortfolio,
        selectedProject,
        histBins,
     } = props;

    const user = useContext(authContext);
    const [res, setRes] = useState()

    let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/assets/${selectedAssetProperties.index}/scenario/${scenarioLabel}/financial/hist`, {
                        method: 'GET',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': selectedProject,
                        }
                    })
                        .then(r => r.json())
                        .then(r => setRes(r))
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.error(`[APPS_LIST] Error while fetching asset level data for histogram data: ${error}`)
            })
    }, [user, scenarioLabel, selectedAssetProperties, selectedPortfolio, selectedProject, histBins])

    if (res === undefined) {
        return (
        <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
            Loading...
        </div>
        );
    } else if (histBins === undefined) {
        return (
          <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
            Loading...
          </div>
        );
    } else if (Number(res['metadata']['asset_id']) !== selectedAssetProperties.index) {
        return (
          <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
            Loading...
          </div>
        );
    } else if (res['payload'] === null) {
        return (
          <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
            Error
          </div>
        );
      }
    
    const summaryData = res['payload'];
    
    const findBin = (number, breaks) => {
        if (number < breaks[0]) {
            return [-1];
        }

        const result = Array(breaks.length);
        for (let i = 0; i < breaks.length - 1; i++) {
            if (number >= breaks[i] && number < breaks[i + 1]) {
                result[i] = 1;
                return result;
            }
        }

        result[breaks.length - 1] = 100;
        return result;
    };
    const summaryScore = {
        "Wildfire": findBin(summaryData.wildfire.summary_score, histBins.wildfire[1]),
        "Flood": findBin(summaryData.flood.summary_score, histBins.flood[1]),
        "Cyclone": findBin(summaryData.cyclone.summary_score, histBins.cyclone[1]),
        "Heatwave": findBin(summaryData.heatwave.summary_score, histBins.heatwave[1]),
        "SLR": findBin(summaryData.SLR.summary_score, histBins.seaLevelRise[1]),
        "Water Stress": findBin(summaryData.water_stress.summary_score, histBins.waterStress[1]),
    }

    let data = {
        "wildfire": {
            labels: histBins.wildfire[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Wildfire,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                    borderWidth: 3,
                    borderColor: '#d00b0b',
                },
                {
                    label: 'Wildfire',
                    data: histBins.wildfire[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        },
        "flood": {
            labels: histBins.flood[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Flood,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                    borderWidth: 3,
                    borderColor: '#d00b0b',
                },
                {
                    label: 'Flood',
                    data: histBins.flood[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        },
        "cyclone": {
            labels: histBins.cyclone[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Cyclone,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                    borderWidth: 3,
                    borderColor: '#d00b0b',
                },
                {
                    label: 'Cyclone',
                    data: histBins.cyclone[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        },
        "heatwave": {
            labels: histBins.heatwave[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.Heatwave,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                    borderWidth: 3,
                    borderColor: '#d00b0b',
                },
                {
                    label: 'Heatwave',
                    data: histBins.heatwave[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],

        },
        "SLR": {
            labels: histBins.seaLevelRise[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore.SLR,
                    backgroundColor: '#d00b0b',
                    type: 'scatter',
                    borderWidth: 3,
                    borderColor: '#d00b0b',
                },
                {
                    label: 'SLR',
                    data: histBins.seaLevelRise[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        },
        "water_stress": {
            labels: histBins.waterStress[0].map(item => item),
            datasets: [
                {
                    label: 'bin',
                    data: summaryScore["Water Stress"],
                    backgroundColor: '#ff0000',
                    type: 'scatter',
                    borderWidth: 3,
                    borderColor: '#d00b0b',
                },
                {
                    label: 'Water Stress',
                    data: histBins.waterStress[0],
                    backgroundColor: 'gray',
                    borderColor: '#fff',
                    borderWidth: 1,
                },
            ],
        },
    };

    const title_options = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        style: {
            style: {
                height: '10px',
            }
        },
        scales: {
            x: {
              ticks: {
                display: false // This will hide the x-axis labels
              },
              grid: {
                display: true// Optional: also hide the grid lines
              }
            },
            y: {
              beginAtZero: false,
              ticks: {
                display: false // This will hide the y-axis labels
              },
              grid: {
                display: true // Optional: also hide the grid lines
              }
            }
          },
        plugins: { 
          legend: {
            display: false,
          },
          labels: {
            display: false,
            // usePointStyle: true,
          },
          title: {
            display: true,
            text: 'Distribution of Exposure Across Portfolio',
            color: 'gray',
            position:'top',
            font: {
              size: 16,
            }
          },
        },
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        style: {
            style: {
                height: '10px',
            }
        },
        scales: {
            x: {
              ticks: {
                display: false // This will hide the x-axis labels
              },
              grid: {
                display: true// Optional: also hide the grid lines
              }
            },
            y: {
                beginAtZero: false,
              ticks: {
                display: false // This will hide the y-axis labels
              },
              grid: {
                display: true // Optional: also hide the grid lines
              }
            }
          },
        plugins: {
            legend: {
                display: false,
            },
            labels: {
                display: false,
            },
        },
    };

    const bottomOptions = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        style: {
            style: {
                height: '10px',
            }
        },
        scales: {
            x: {
              ticks: {
                display: true,
                callback: function(value, index, ticks) {
                    return '0.' + value;
                }
              },
              grid: {
                display: true// Optional: also hide the grid lines
              }
            },
            y: {
                beginAtZero: false,
              ticks: {
                display: false // This will hide the y-axis labels
              },
              grid: {
                display: true // Optional: also hide the grid lines
              }
            }
          },
        plugins: {
            legend: {
                display: false,
            },
            labels: {
                display: false,
            },
        },
    };

    return (
        <div style={{display: 'grid', gridTemplateColumns: '13% 1fr', height: '100%'}}>
            <div className='axis-label'>Wildfire</div>
            <div style={{ height: '75px' }}>
                <Bar data={data.wildfire} options={title_options}/>
            </div> 
            <div className='axis-label'>Flood</div>
            <div style={{ height: '37.5px' }}>
                <Bar data={data.flood} options={options}/>
            </div>
            <div className='axis-label'>Cyclone</div>
            <div style={{ height: '37.5px' }}>
                <Bar data={data.cyclone} options={options}/>
            </div>
            <div className='axis-label'>Heatwave</div>
            <div style={{ height: '37.5px' }}>
                <Bar data={data.heatwave} options={options}/>
            </div>
            <div className='axis-label' style={{fontSize: "1.2em"}}>Sea Level Rise</div>
            <div style={{ height: '37.5px' }}>
                <Bar data={data.SLR} options={options}/>
            </div>
            <div className='axis-label' style={{alignSelf: 'center'}}>Water Stress</div>
            <div style={{ height: '50px' }}>
                <Bar data={data.water_stress} options={bottomOptions}/>
            </div> 
        </div>
    )
}


export default ExposureHistogram;