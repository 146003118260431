import React, {useState, useContext, useEffect} from 'react';
import { Bar } from "react-chartjs-2";
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config'

const BusinessInterruptBarGraph = (props) => {
  const { selectedTemperatureScenario, selectedAssetProperties, selectedPortfolio, selectedProject } = props;

  let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
  const user = useContext(authContext);
  const [res, setRes] = useState();

  useEffect(() => {
    user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/assets/${selectedAssetProperties.index}/scenario/${scenarioLabel}/financial/bi`, {
                    method: 'GET',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': selectedProject,
                    }
                })
                    .then(r => r.json())
                    .then(r => setRes(r))
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`[APPS_LIST] Error while fetching asset level data for business interruption: ${error}`)
        })
  }, [user, scenarioLabel, selectedAssetProperties, selectedPortfolio, selectedProject])
  
  if (res === undefined) {
    // We're loading a new asset in on first use after login
    return (
      <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
        Loading...
      </div>
    );
  } else if (res['metadata']['asset_id'] != selectedAssetProperties.index) {
    // We're loading a new asset in
    return (
      <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
        Loading...
      </div>
    );
  } else if (res['payload'] === null) {
    // Payload is in an error state was not provided
    return (
      <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
        Error
      </div>
    );
  }

  const labels = ["Baseline", "2030", "2050", "2080"];
  const businessInterruption = res['payload'];

  const wildfireData = labels.map((label) => businessInterruption[label].Wildfire);
  const floodData = labels.map((label) => businessInterruption[label].Flood);
  const cycloneData = labels.map((label) => businessInterruption[label].Cyclone);
  const heatwaveData = labels.map((label) => businessInterruption[label].Heatwave);

  const data = {
    labels: labels,
    title: "Business Interruption",
    labelColor: 'white',
    datasets: [
      {
        label: "Lower",
        data: labels.map((label) => businessInterruption[label].lbd),
        backgroundColor: '#505cc5',
        borderColor: '#505cc5',
        borderWidth: 3, 
        pointRadius: 2, 
        type: "line",
        stack: "stack 0",
      },
      {
        label: "Upper",
        data: labels.map((label) => businessInterruption[label].ubd),
        backgroundColor: 'rgba(255, 90, 90, 1)',
        borderColor: 'rgba(255, 90, 90, 1)',
        borderWidth: 3,
        pointRadius: 2,
        type: "line",
        stack: "stack 0",
      },
      {
        label: "Wildfire",
        data: wildfireData,
        backgroundColor: "#fd8d3c",
        stack: "stack 1",
      },
      {
        label: "Flood",
        data: floodData,
        backgroundColor: "#6baed6",
        stack: "stack 1",
      },
      {
        label: "Cyclone",
        data: cycloneData,
        backgroundColor: "#74c476",
        stack: "stack 1",
      },
      {
        label: "Heatwave",
        data: heatwaveData,
        backgroundColor: "#fdae6b",
        stack: "stack 1",
      },
    ],
  };

  const options = {
    indexAxis: "x",
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {      
          color: 'gray',
      },
        stacked: true,
        beginAtZero: true,
      },
      y: {
        stacked: true,
        ticks: {      
          color: 'gray',
      },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 15,
          color: 'gray',
        },
      },
      title: {
        display: true,
        text: "Business Interruption (0 to 1)",
        color: "gray",
        font: {
          size: 16,
        }
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BusinessInterruptBarGraph;
