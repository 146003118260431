import React, { useState, useEffect } from "react";
import "./Explore.css";


const Explore = () => {


  return (
    <div >
      Explore
    </div>
  );
};

export default Explore;
