import React, {useContext, useEffect, useState} from 'react';
import {auth} from '../../context/firebase-config';
import {useNavigate} from 'react-router-dom'
import {Field, Form, Formik} from 'formik';
import {sendPasswordResetEmail} from 'firebase/auth';
import {authContext} from "../../context/authContext";

const PasswordReset = () => {
    useEffect(() => {
        document.title = "Password Reset";
    }, []);
    const [errors, setErrors] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const initialValues = {email: ""}
    const user = useContext(authContext);
    const navigate = useNavigate();

    const validateForm = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = "Email is required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }
        return errors;
    }

    const onSubmit = (values) => {
        setLoading(true);
        sendPasswordResetEmail(auth, values.email)
            .then(() => {
                setLoading(false);
                setSuccess(true)
            })
            .catch((error) => {
                setLoading(false);
                setSuccess(false)
                setErrors("Could not send reset password email");
            });
    }

    useEffect(() => {
        if (success) {
            navigate('/account/password_reset/done')
        }
    }, [navigate, success]);

    if (user) {
        navigate('/index')
    }

    return (<>
        <main>
            <section style={{marginTop: 30}}>
                <div>
                    <div>
                        <section>
                            <div className="messages d-flex justify-content-center centered-form"
                                 style={{display: "inline-block"}}>
                                {errors && <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    style={{color: "red"}}>
                                    {errors && errors}
                                </div>}
                            </div>
                            <div className="d-flex justify-content-center centered-form">
                                <Formik initialValues={initialValues} validate={validateForm}
                                        onSubmit={(values) => onSubmit(values)}>
                                    {({
                                          values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting
                                      }) => (<Form>
                                        <h3 style={{textAlign: "center"}}>Password Reset</h3>
                                        <div>
                                            <div className="form-group mt-2">
                                                Request a password reset for an existing account.
                                                <br/>
                                                <br/>
                                                <Field type="email" id="email" name="email" value={values.email}
                                                       className="textinput form-control"
                                                       maxLength="254"
                                                       size="35"
                                                       onChange={handleChange}
                                                       placeholder="Email address"/>
                                                <p style={{color: 'red'}}>
                                                    {errors.email && touched.email && errors.email}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <button type="submit" className="btn btn-primary"
                                                    style={{width: "330px", fontWeight: 600}}>
                                                <span>{loading ? "Requesting Password Reset..." : " Reset Password "}</span>
                                            </button>
                                        </div>
                                    </Form>)}
                                </Formik>
                            </div>
                        </section>
                    </div>
                </div>
            </section>
        </main>
    </>)
}

export default PasswordReset