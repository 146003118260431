import React from 'react';

const handleBackgroundClick = (e, onClose) => {
    if (e.target === e.currentTarget) {
        onClose();
    }
};

const ModalShow = ({children, onClose, isVisible}) => {
    return isVisible && (
        <div className={"modal show"} style={{display: "block"}} aria-modal={"true"} role={"dialog"}
             onClick={(event) => handleBackgroundClick(event, onClose)}>
            <div className="modal-dialog" role={"document"}>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalShow;
