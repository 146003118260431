import React, { useState } from 'react';
import IndexMap from '../Index/IndexMap';
const Report = () => {

    return (
        <div >
          Report
        </div>
      );
}

export default Report;