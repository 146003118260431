import React, { useState, useEffect } from "react";
import "./YearRangeSelector.css";

const YearRangeSelector = (props) => {
  const { 
          yearRanges,
          selectedYearRange,
          setSelectedYearRange
         } = props;

  const handleYearChange = (e) => {
    setSelectedYearRange(e);
  }
  return (
    <div className="year-selector-container">
      <div className="year-selector">
        {yearRanges.map((year) => (
          <div
            key={year}
            className={`year-option ${
              selectedYearRange === year ? "selected" : ""
            }`}
            onClick={() => handleYearChange(year)}
          >
            {year}
          </div>
        ))}
      </div>
    </div>
  );
}

export default YearRangeSelector;