import React from "react";
import "./Index.css";
import IndexMap from "./IndexMap";

const Index = (props) => {
  const { selectedProject } = props;
  const hazards = [
    "wildfire",
    "flood",
    "cyclone",
    "heatwave",
    "SLR",
    "water_stress",
  ];
  
  return (
    <div id="index-container">
      <IndexMap
        hazards={hazards}
        selectedProject={selectedProject}
      />
    </div>
  );
};

export default Index;
