import React, {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/authContext";
import {ce_default_url} from "../../context/firebase-config";

const PortfolioDeleteConfirmForm = ({project, portfolioName, onClose, onSuccess, onError}) => {
    const user = useContext(authContext);
    const [apiKey, setApiKey] = useState(null);

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    setApiKey(token.claims.api_key)
                } else {
                    console.error('api key not available for user')
                    onError('could not retrieve API key for user')
                }
            })
    }, [user, onError]);

    const handleSubmit = () => {
        fetch(`${ce_default_url}/api/portfolios/${portfolioName}/`, {
            method: 'DELETE',
            headers: {
                'X-SustGlobal-APIKey': apiKey,
                'X-SustGlobal-Project': `${project}`,
            }
        })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.error(`could not delete portfolio: ${error}`)
            onError(`could not delete portfolio: ${error}`)
        })

        onSuccess('portfolio successfully deleted')
        onClose()
    }

    return (
        <form onSubmit={() => handleSubmit()}>
            <div className="modal-header bg-black" style={{border: "1px solid grey"}}>
                <h5 className="modal-title" style={{color: "#ffffff"}}>Delete Portfolio {portfolioName}</h5>
                <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="modal-body form-group bg-black" style={{color: "#ffffff", border: "1px solid grey"}}>
                Are you sure you want to delete this portfolio? This is a permanent action.
            </div>
            <div className="modal-footer bg-black" style={{border: "1px solid grey"}}>
                <button type="submit" className={"btn btn-danger"}>Delete</button>
                <button type="button" className={"btn btn-secondary"} onClick={onClose}>Close</button>
            </div>
        </form>
    );
}

export default PortfolioDeleteConfirmForm;