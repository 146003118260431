import React from 'react';
import { Bar } from "react-chartjs-2";

const PeerCompareBarGraph = (props) => {
  const { 
      type,
      selectedCompany,
      peerComparison,
    } = props;

  if (peerComparison === undefined) {
    return (
      <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
        Loading...
      </div>
    );
  } else if (peerComparison.company !== selectedCompany) {
    // We're loading a new asset in
    return (
      <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
        Loading...
      </div>
    );
  } else if (peerComparison.peers === null) {
    // Peers was not provided, error state
    return (
      <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
        Error
      </div>
    );
  }

  var assets = peerComparison.peers;
  assets = assets.sort((a, b) => (b[type].total_risk - a[type].total_risk));
  assets = assets.slice(0, 10)

  let labelNames = assets.map(item => String(item.label).slice(0,10));
  let wildfireData = assets.map(item => item[type].Wildfire);
  let floodData = assets.map(item => item[type].Flood);
  let cycloneData = assets.map(item => item[type].Cyclone);
  let heatwaveData = assets.map(item => item[type].Heatwave);
  if (type === "sd") {
    wildfireData.map(x => x * 100);
    floodData.map(x => x * 100);
    cycloneData.map(x => x * 100);
    heatwaveData.map(x => x * 100);
  }
  
  const data = {
    labels: labelNames,
    datasets: [
      {
        label: 'Wildfire',
        data: wildfireData,
        backgroundColor: '#fd8d3c',
      },
      {
        label: 'Flood',
        data: floodData,
        backgroundColor: '#6baed6',
      },
      {
        label: 'Cyclone',
        data: cycloneData,
        backgroundColor: '#74c476',
      }
    ],
  };
  if (type === "bi") {
    data.datasets.push({
      label: 'Heatwave',
      data: heatwaveData,
      backgroundColor: '#fdae6b',
    })
  }
  const options = {
      barThickness: 16,
      maxBarThickness: 18,
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
          beginAtZero: true,
          ticks: {
            color: 'gray',
          },
        },
        y: {
          stacked: true,
          ticks: {
              color: 'gray',
              font: {
                size: 12, 
              },
            },
        },
      },
      plugins: {
          legend: {
              display: true,
              labels: {
                boxWidth: 15,
                color: 'gray',
              },
          },
          title: {
            display: true,
            text: 'Assets most at risk from ' + (type === "sd" ? "Structural Damage" : "Business Interruption"),
            color: 'gray',
            font: {
              size: 16,
            }
        },
      },
    };

  return (
      <div style={{ width: '100%', height: '100%', backgroundColor: '#000'}}>
          <Bar data={data} options={options}/>
      </div>
  )
};
  
export default PeerCompareBarGraph;