import React, {createContext, useEffect, useState} from "react";

import {auth} from "./firebase-config";
import {onAuthStateChanged, signInWithCustomToken} from "firebase/auth";
import {Spinner} from "react-bootstrap";
import {useSearchParams} from 'react-router-dom';

export const authContext = createContext(auth.currentUser);

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(auth.currentUser);
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        onAuthStateChanged(auth, setUser);
    }, [user]);

    useEffect(() => {
        auth.authStateReady().then(() => {
            setUser(auth.currentUser)
            const t = searchParams.get('t')
            if (t) {
                const token = t.slice(2, t.length - 1);
                signInWithCustomToken(auth, token)
                    .then((userCredential) => {
                        setUser(userCredential.user)
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error(`error signing with custom token: ${error}`)
                    });
            } else {
                setLoading(false);
            }
        });
    }, [user]);

    if (loading) {
        return <div className="flex items-center justify-center h-[400px] ">
            <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>
        </div>
    } else {
        return (<authContext.Provider value={user}>
            {children}
        </authContext.Provider>);
    }
}
