import React, { useState } from 'react';
import Login from '../../routes/login/Login';

const LandingPage = () => {

    return (
        <Login/>
    )
}

export default LandingPage;