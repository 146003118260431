import React, {useContext, useEffect, useState} from "react";
import ModalShow from "./Modal";
import PortfolioUploadForm from "./PortfolioUploadForm";
import PortfolioDeleteConfirmForm from './PortfolioDeleteConfirmForm'
import ErrorMessage from './ErrorMessage'
import {FaEllipsisV} from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import {authContext} from "../../context/authContext";
import {ce_default_url} from "../../context/firebase-config";
import SuccessMessage from "./SuccessMessage";

const PortfolioList = (props) => {
    const {
        selectedProject,
    } = props;

    const user = useContext(authContext);

    const [showPortfolioUploadModal, setPortfolioUploadModal] = useState(false);
    const [showPortfolioDeleteModal, setPortfolioDeleteModal] = useState(false);
    const [showPortfolioError, setShowPortfolioError] = useState(false);
    const [showPortfolioSuccess, setShowPortfolioSuccess] = useState(false);
    const [activeClass, setActiveClass] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [portfolios, setPortfolios] = useState([])
    const [activePortfolio, setActivePortfolio] = useState(null)
    const [isProjectEditor, setIsProjectEditor] = useState(false);

    useEffect(() => {
        document.body.className = activeClass;
    }, [activeClass]);

    useEffect(() => {
        fetchPortfolios(user, 'GET', 'portfolios/financial/grid', selectedProject, setPortfolios)
    }, [user, selectedProject, successMessage])

    useEffect(() => {
        setShowPortfolioError(!!errorMessage)
    }, [errorMessage]);

    useEffect(() => {
        setShowPortfolioSuccess(!!successMessage)
    }, [successMessage]);

    useEffect(() => {
        user && user.getIdTokenResult()
            .then((token) => {
                if (!!token.claims.api_key) {
                    fetch(`${ce_default_url}/api/financial/project/authcheck`, {
                        method: 'GET',
                        headers: {
                            'X-SustGlobal-APIKey': token.claims.api_key,
                            'X-SustGlobal-Project': selectedProject,
                        }
                    })
                        .then(r => r.json())
                        .then(r => {
                            console.log(`Is editor? ${r.is_editor}`);
                            setIsProjectEditor(r.is_editor)
                        })
                } else {
                    console.error('api key not available for user')
                }
            })
            .catch((error) => {
                console.error(`[AUTH_CHECK] Error while checking project authorization: ${error}`)
            })
    }, [user])


    const openPortfolioUpload = () => {
        setPortfolioUploadModal(true);
        setActiveClass('modal-open');
    }
    const closePortfolioUpload = () => {
        setPortfolioUploadModal(false);
        setActiveClass('');
    }
    const openPortfolioDelete = (portfolio) => {
        setPortfolioDeleteModal(true);
        setActivePortfolio(portfolio)
        setActiveClass('modal-open');
    }
    const closePortfolioDelete = () => {
        setPortfolioDeleteModal(false);
        setActivePortfolio(null)
        setActiveClass('');
    }
    const openPortfolioSuccessModal = (msg) => {
        setSuccessMessage(msg)
    }
    const closePortfolioSuccessModal = () => {
        setSuccessMessage(null)
    }
    const openPortfolioErrorModal = (errMsg) => {
        setErrorMessage(errMsg)
    }
    const closePortfolioErrorModal = () => {
        setErrorMessage(null)
    }

    let portfolioList = portfolioParser(user, selectedProject, portfolios, openPortfolioDelete);

    return (
        <div id="content" className={"bg-black"}
             style={{
                 maxWidth: "1275px", minWidth: "1275px", margin: "10px auto 0 auto", padding: "0 15px",
                 fontSize: "1rem", fontWeight: "500", lineHeight: "1.5", minHeight: "1250px"
             }}>

            <ModalShow onClose={closePortfolioUpload} isVisible={showPortfolioUploadModal}>
                <PortfolioUploadForm project={selectedProject} onError={openPortfolioErrorModal}
                                     onSuccess={openPortfolioSuccessModal} onClose={closePortfolioUpload}/>
            </ModalShow>
            <ModalShow onClose={closePortfolioDelete} isVisible={showPortfolioDeleteModal}>
                <PortfolioDeleteConfirmForm project={selectedProject} portfolioName={activePortfolio}
                                            onError={openPortfolioErrorModal} onSuccess={openPortfolioSuccessModal}
                                            onClose={closePortfolioDelete}/>
            </ModalShow>
            <ErrorMessage msg={errorMessage} onClose={closePortfolioErrorModal} isVisible={showPortfolioError}/>
            <SuccessMessage msg={successMessage} onClose={closePortfolioSuccessModal} isVisible={showPortfolioSuccess}/>

            <div className={"d-flex align-items-center"}>
                <h3 className={"modal-title"}
                    style={{color: "#ffffff", fontWeight: "550", fontSize: "1.6rem"}}>Portfolio Management</h3>
                <div className="ms-auto">
                    <button className="btn btn-primary btn-sm" onClick={openPortfolioUpload} disabled={!isProjectEditor}>
                        New Portfolio
                    </button>
                </div>
            </div>
            <table className={"table table-dark"} style={{backgroundColor: "black"}}>
                <thead style={{borderBottomColor: "black"}}>
                <tr>
                    <th width="30px"></th>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Assets</th>
                </tr>
                </thead>
                <tbody>
                {portfolioList}
                </tbody>
            </table>
        </div>
    );
}

const fetchPortfolios = (user, method, url, project, dataSetter) => {
    user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${ce_default_url}/api/${url}`, {
                    method: `${method}`,
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': `${project}`,
                    }
                })
                    .then(r => r.json())
                    .then(r => dataSetter(r))
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`error while fetching from API: ${error}`)
        })
}

const portfolioParser = (user, project, portfolioList, onDeletePortfolio) => {
    const dateFormatter = (dateStr) => {
        let date = new Date(dateStr)
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }).replace(' at ', ' ');
    }

    if (portfolioList.length > 0) {
        return portfolioList.map(
            (p, index) => {
                const pm = portfolioMenu(user, project, onDeletePortfolio, p.portfolio_name)
                return (
                    <tr key={p.portfolio_name}>
                        <td className="align-middle">{pm}</td>
                        <td className="align-middle"><b>{p.portfolio_name}</b></td>
                        <td className="align-middle"><i>{p.status}</i></td>
                        <td className="align-middle">{dateFormatter(p.created_at)}</td>
                        <td className="align-middle">{dateFormatter(p.updated_at)}</td>
                        <td className="align-middle">{p.number_of_assets}</td>
                    </tr>
                )
            }
        )
    }
}

const portfolioMenu = (user, project, onDeletePortfolio, portfolioName) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="secondary">
                <FaEllipsisV size={14} style={{width: '6px'}}/>
            </Dropdown.Toggle>
            <Dropdown.Menu data-bs-theme="dark" style={{textAlign: 'left'}}>
                <Dropdown.Item
                    style={{border: '1px solid #00000026', fontWeight: '500'}}
                    onClick={() => handleVSAssetsDownload(user, project, portfolioName)}>
                    Download Assets
                </Dropdown.Item>
                <Dropdown.Item
                    style={{border: '1px solid #00000026', fontWeight: '500'}}
                    onClick={() => handleVSArchiveDownload(user, project, portfolioName)}>
                    Download Risk Exposure
                </Dropdown.Item>
                <Dropdown.Item
                    onClick={() => onDeletePortfolio(portfolioName)}
                    style={{border: '1px solid #00000026', fontWeight: '500', color: "#dc3545"}}>
                    Destroy Portfolio
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

const handleVSArchiveDownload = (user, project, portfolio) => {
    user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${ce_default_url}/api/portfolios/${portfolio}/datasets/financial/export`, {
                    method: 'GET',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': `${project}`,
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `${portfolio}-vs.zip`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove()
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        console.error(`error while fetching from API: ${error}`)
                    });
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`error while fetching from API: ${error}`)
        })
}

const handleVSAssetsDownload = (user, project, portfolio) => {
    user && user.getIdTokenResult()
        .then((token) => {
            if (!!token.claims.api_key) {
                fetch(`${ce_default_url}/api/portfolios/${portfolio}/financial/assets`, {
                    method: 'GET',
                    headers: {
                        'X-SustGlobal-APIKey': token.claims.api_key,
                        'X-SustGlobal-Project': `${project}`,
                    },
                })
                    .then(async (response) => {
                        const contentDisposition = response.headers.get('content-disposition');
                        const fileName = contentDisposition
                            ? contentDisposition.split('filename=')[1]
                            : `${portfolio}_combined_locations.csv`;

                        const blob = await response.blob();
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = `${fileName}`;
                        document.body.appendChild(link);
                        link.click();
                        link.remove()
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((error) => {
                        console.error(`error while fetching from API: ${error}`)
                    });
            } else {
                console.error('api key not available for user')
            }
        })
        .catch((error) => {
            console.error(`error while fetching from API: ${error}`)
        })
}

export default PortfolioList;