import React, {useState, useContext, useEffect} from 'react';
import { Bar } from "react-chartjs-2";
import {authContext} from "../../context/authContext";
import {ce_default_url} from '../../context/firebase-config'

const ExposureBarGraph = (props) => {
    const { 
        selectedTemperatureScenario,
        selectedAssetProperties,
        selectedPortfolio,
        selectedProject
     } = props;

     let scenarioLabel =  selectedTemperatureScenario === "4.3°C" ? "ssp585" : "ssp245";
     const user = useContext(authContext);
     const [res, setRes] = useState();
   
     useEffect(() => {
       user && user.getIdTokenResult()
           .then((token) => {
               if (!!token.claims.api_key) {
                   fetch(`${ce_default_url}/api/portfolios/${selectedPortfolio}/assets/${selectedAssetProperties.index}/scenario/${scenarioLabel}/financial/hazard`, {
                       method: 'GET',
                       headers: {
                           'X-SustGlobal-APIKey': token.claims.api_key,
                           'X-SustGlobal-Project': selectedProject,
                       }
                   })
                       .then(r => r.json())
                       .then(r => setRes(r))
               } else {
                   console.error('api key not available for user')
               }
           })
           .catch((error) => {
               console.error(`[APPS_LIST] Error while fetching asset level data for hazard data: ${error}`)
           })
     }, [user, scenarioLabel, selectedAssetProperties, selectedPortfolio, selectedProject])

     if (res === undefined) {
      return (
        <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
          Loading...
        </div>
      );
    } else if (Number(res['metadata']['asset_id']) !== selectedAssetProperties.index) {
      return (
        <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
          Loading...
        </div>
      );
    } else if (res['payload'] === null) {
      return (
        <div style={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
          Error
        </div>
      );
    }

    const summaryScore = res['payload']
    const getRiskBarColor = (summary_label) => {
            switch (summary_label) {
              case 'LOW':
                return '#89DA59';
              case 'MEDIUM':
                return '#FBB13C';
              case 'HIGH':
                return '#ff0000';
              default:
                return 'gray';
            }
          };
    
    const chartData = summaryScore;
    let labelValues = chartData.map(item => item.hazard);
    const dataValues = chartData.map(item => item.score);
    const backgroundColors = chartData.map(item => getRiskBarColor(item.risk_label));

    var index = labelValues.indexOf("SLR");
    if (index !== -1) {
      labelValues[index] = "Sea Level Rise";
    }

    let data = {
        labels: labelValues,
        datasets: [
            {
                label: 'Exposure',
                data: dataValues,
                backgroundColor: backgroundColors,
            },
        ],
    };
    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
              color: 'gray',
            },
          },
          y: {
            ticks: {
              color: 'gray',
              font: {
                size: 14, 
              },
            },
          },
        },
        plugins: {
          legend: {
              display: false,
          },
          title: {
            display: true,
            text: 'Hazard Exposure (%)',
            color: 'gray',
            font: {
              size: 16,
            }
          },
        },
      };


    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#000'}}>
            <Bar data={data} options={options}/>
        </div>
    )
}


export default ExposureBarGraph;