import React, { useState } from 'react';
import UserProfile from './Profile';

const Account = () => {

    return (
        <UserProfile />
    )
}

export default Account;