import React from 'react';
import './DropdownMenu.css'; 

const DropdownMenu = ({ options, onSelect, label }) => {
  return (
    <div className="mapdropdown">
      <select className="mapdropdown-select" onChange={e => onSelect(e.target.value)} defaultValue="">
        <option value="" disabled>{label}</option>
        {options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

export default DropdownMenu;
